import { WebRequest } from '@analyzer/core/lib/collector/collectors/web.js';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useWebAnalysisDebugQuery } from '../api/analysis.js';

function RequestComparisonList({ matches }: { matches: WebRequest[][] }) {
  // Helper function to render URL chip with tooltip
  const renderUrlChip = (url: string, urlMatch: boolean) => (
    <Tooltip title={url}>
      <Chip
        label={url.length < 128 ? url : url.slice(0, 128) + ' ...'}
        size="small"
        sx={{ backgroundColor: urlMatch ? green[400] : red[400], color: 'white' }}
      />
    </Tooltip>
  );

  return (
    <List dense>
      {matches.map((pair, index) => {
        const [req1, req2] = pair;
        const urlMatch = req1.request.url === req2.request.url;
        const hashMatch = req1.response.sha256 === req2.response.sha256;

        return (
          <Fragment key={index}>
            {/* First request */}
            <ListItem sx={{ backgroundColor: urlMatch ? green[100] : red[100], color: 'white' }}>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={1}>
                    <Chip label={req1.request.method} size="small" />
                    {renderUrlChip(req1.request.url, urlMatch)}
                  </Stack>
                }
                secondary={
                  <Stack direction="row" spacing={1}>
                    <Chip label={req1.response.contentType} size="small" />
                    <Chip
                      label={req1.response.sha256}
                      size="small"
                      sx={{ backgroundColor: hashMatch ? green[400] : red[400], color: 'white' }}
                    />
                  </Stack>
                }
              />
            </ListItem>

            {/* Second request */}
            <ListItem sx={{ backgroundColor: urlMatch ? green[100] : red[100], color: 'white' }}>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={1}>
                    <Chip label={req2.request.method} size="small" />
                    {renderUrlChip(req2.request.url, urlMatch)}
                  </Stack>
                }
                secondary={
                  <Stack direction="row" spacing={1}>
                    <Chip label={req2.response.contentType} size="small" />
                    <Chip
                      label={req2.response.sha256}
                      size="small"
                      sx={{ backgroundColor: hashMatch ? green[400] : red[400], color: 'white' }}
                    />
                  </Stack>
                }
              />
            </ListItem>
          </Fragment>
        );
      })}
    </List>
  );
}

export function AnalysisDebugView() {
  const { projectId, analysisId } = useParams();
  if (!projectId || !analysisId) throw new Error('Missing parameter');

  const parsedProjectId = parseInt(projectId, 10);
  const parsedAnalysisId = parseInt(analysisId, 10);

  if (isNaN(parsedProjectId) || isNaN(parsedAnalysisId)) {
    return <Alert severity="error">Invalid parameters: projectId and analysisId must be numbers</Alert>;
  }

  const { data, isLoading, isError, error } = useWebAnalysisDebugQuery(parsedProjectId, parsedAnalysisId);

  if (isLoading) return <CircularProgress />;
  if (isError) return <Alert severity="error">Error loading analysis: {error.message}</Alert>;

  // Compute statistics
  const totalSnapshots = data.snapshots.length;
  const totalMatchedRequests = data.matched.length;
  const totalMissing = data.missing.length;
  const matchRate = ((totalMatchedRequests / (totalMatchedRequests + totalMissing)) * 100).toFixed(2);

  // Split matched requests into categories
  const matches0: WebRequest[][] = [];
  const matches1: WebRequest[][] = [];
  const matches2: WebRequest[][] = [];
  const matches3: WebRequest[][] = [];

  for (const [t0, t1] of data.matched) {
    if (t0.request.url === t1.request.url) {
      if (t0.response.sha256 === t1.response.sha256) {
        matches0.push([t0, t1]);
      } else {
        matches1.push([t0, t1]);
      }
    } else {
      if (t0.response.sha256 === t1.response.sha256) {
        matches2.push([t0, t1]);
      } else {
        matches3.push([t0, t1]);
      }
    }
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Analysis Debug
      </Typography>

      <Accordion defaultExpanded>
        <AccordionSummary>
          <Typography variant="h6">Statistics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            <ListItem>
              <ListItemText primary={`Seen response bodies: ${data.existing.length}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`New response bodies: ${data.missing.length}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Total transactions: ${totalMatchedRequests + totalMissing}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Matched transactions: ${totalMatchedRequests}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Unmatched transactions: ${totalMissing}`} />
            </ListItem>
            <ListItem>
              <ListItemText primary={`Match Rate: ${matchRate}%`} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>
          <Typography variant="h6">Used Snapshots ({totalSnapshots})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {data.snapshots.map((snapshot, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Link to={`/projects/${projectId}/snapshots/${snapshot}/web`}>Snapshot {snapshot}</Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary>
          <Typography variant="h6">New Responses ({data.missing.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {data.missing.map((missing, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Chip label={missing.request.method} size="small" />
                      <Tooltip title={missing.request.url}>
                        <Chip
                          label={
                            missing.request.url.length < 128
                              ? missing.request.url
                              : missing.request.url.slice(0, 128) + ' ...'
                          }
                          size="small"
                        />
                      </Tooltip>
                      <Chip label={missing.response.contentType} size="small" />
                      <Link
                        to={`https://storage.googleapis.com/analyzer-blobs/http-response-bodies/${missing.response.sha256}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </Link>
                    </Stack>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">Old Responses ({data.existing.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {data.existing.map((existing, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Chip label={existing.request.method} size="small" />
                      <Tooltip title={existing.request.url}>
                        <Chip
                          label={
                            existing.request.url.length < 128
                              ? existing.request.url
                              : existing.request.url.slice(0, 128) + ' ...'
                          }
                          size="small"
                        />
                      </Tooltip>
                      <Chip label={existing.response.contentType} size="small" />
                      <Link
                        to={`https://storage.googleapis.com/analyzer-blobs/http-response-bodies/${existing.response.sha256}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </Link>
                    </Stack>
                  }
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">URL & Content Matches ({matches0.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequestComparisonList matches={matches0} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">URL Matches but Content Changed ({matches1.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequestComparisonList matches={matches1} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">Heuristic URL Match and Content Match ({matches2.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequestComparisonList matches={matches2} />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary>
          <Typography variant="h6">Heuristic URL Match but Content Changed ({matches3.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequestComparisonList matches={matches3} />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
