import { PaginatedResponse, Project, ProjectStats, QueryParams } from '@analyzer/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useService } from '../providers/ServiceProvider';

export function useProjectListQuery(queryParams?: QueryParams<Project>) {
  const client = useService();
  return useQuery<PaginatedResponse<Project>, Error>(
    ['project-list', queryParams],
    () => client.projectList(queryParams),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );
}

// Pagination management hook
export function useProjectPagination(initialLimit = 10) {
  // Manage query parameters state
  const [queryParams, setQueryParams] = useState<QueryParams<Project>>({
    limit: initialLimit,
    offset: 0,
    sortField: 'createdDate',
    sortOrder: 'DESC'
  });

  // Use the base query hook
  const query = useProjectListQuery(queryParams);

  // Reset pagination when search changes
  const setSearch = useCallback((search?: string) => {
    setQueryParams((prev) => ({
      ...prev,
      search,
      offset: 0
    }));
  }, []);

  // Update sort parameters
  const setSort = useCallback((sortField: keyof Project, sortOrder: 'ASC' | 'DESC') => {
    setQueryParams((prev) => ({
      ...prev,
      sortField,
      sortOrder
    }));
  }, []);

  // Go to next page if there are more results
  const nextPage = useCallback(() => {
    if (query.data?.metadata.hasMore) {
      setQueryParams((prev) => ({
        ...prev,
        offset: (prev.offset || 0) + (prev.limit || initialLimit)
      }));
    }
  }, [query.data?.metadata.hasMore, initialLimit]);

  // Go to previous page if not on first page
  const previousPage = useCallback(() => {
    setQueryParams((prev) => ({
      ...prev,
      offset: Math.max(0, (prev.offset || 0) - (prev.limit || initialLimit))
    }));
  }, [initialLimit]);

  return {
    ...query, // Spread all react-query properties (data, isLoading, error, etc)
    queryParams, // Current query parameters
    setQueryParams, // Allow direct params updates
    setSearch, // Update search term
    setSort, // Update sort parameters
    nextPage, // Go to next page
    previousPage, // Go to previous page
    hasNextPage: query.data?.metadata.hasMore ?? false,
    hasPreviousPage: (queryParams.offset || 0) > 0
  };
}

export function useProjectCreateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { name: string; description: string }) => client.projectCreate(data.name, data.description),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-list']);
      }
    }
  );
}

export function useProjectReadQuery(projectId: number | undefined) {
  const client = useService();
  return useQuery<Project, Error>({
    queryKey: ['project-read', projectId],
    queryFn: () => client.projectRead(projectId!),
    enabled: projectId !== undefined
  });
}

export function useProjectStatsQuery(projectId: number | undefined) {
  const client = useService();
  return useQuery<ProjectStats, Error>({
    queryKey: ['project-stats', projectId],
    queryFn: () => client.projectStats(projectId!),
    enabled: projectId !== undefined
  });
}

export function useProjectUpdateMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation(
    (data: { projectId: number; project: { name?: string; description?: string } }) =>
      client.projectUpdate(data.projectId, data.project),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['project-list']);
      }
    }
  );
}

export function useProjectImportMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((data: string) => client.projectImport(data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project-list']);
    }
  });
}

export function useProjectExportQuery() {
  const client = useService();
  return useQuery<any, Error>(['project-export'], () => client.projectExport(), {
    staleTime: Infinity,
    cacheTime: 0
  });
}

export function useProjectDestroyMutation() {
  const client = useService();
  const queryClient = useQueryClient();
  return useMutation((projectId: number) => client.projectDestroy(projectId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['project-list']);
    }
  });
}
