import {
  AccountTree,
  Analytics,
  Assessment,
  Camera,
  Dns,
  Hub,
  Image,
  KeyboardArrowRight,
  Link as LinkIcon,
  Pause,
  RocketLaunch,
  Science,
  ScreenshotMonitor
} from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useProjectReadQuery, useProjectStatsQuery } from '../api/project.js';
import { useService } from '../providers/ServiceProvider.js';

interface MetricCardProps {
  icon: ReactNode;
  title: string;
  count: number;
  link: string;
}

export function ProjectView() {
  const { projectId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const client = useService();

  const { data: project, isLoading: isProjectLoading } = useProjectReadQuery(parseInt(projectId!));
  const { data: stats, isLoading: isStatsLoading } = useProjectStatsQuery(parseInt(projectId!));

  if (isProjectLoading || isStatsLoading) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress color="inherit" />
      </Paper>
    );
  }

  if (!project) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          Project not found or failed to load.
        </Typography>
      </Paper>
    );
  }

  if (!stats) {
    return (
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6" color="error">
          Failed to load project stats.
        </Typography>
      </Paper>
    );
  }

  const handleCreateSnapshot = async () => {
    try {
      const tests = await client.testList(project.id);
      const snapshotPromises = tests.map(async (test) => {
        try {
          const job = await client.snapshotCreate('web', project.id, test.id);
          enqueueSnackbar(`Snapshot job (${job.id}) created for test ID ${test.id}.`, {
            variant: 'success'
          });
        } catch (error) {
          enqueueSnackbar(`Failed to create snapshot job for test ${test.id}: ${error}`, {
            variant: 'error'
          });
        }
      });
      await Promise.all(snapshotPromises);
    } catch (error) {
      enqueueSnackbar(`Failed to fetch tests: ${error}`, { variant: 'error' });
    }
  };

  const handleDisableProject = async () => {
    try {
      const updatedEnabledState = !project.enabled;
      await client.projectUpdate(project.id, { enabled: updatedEnabledState });

      enqueueSnackbar(`${updatedEnabledState ? 'Enabled' : 'Disabled'} project successfully.`, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar('Failed to update project state. Please try again.', { variant: 'error' });
    }
  };

  const handleCreateAnalysis = async () => {
    try {
      const [snapshot] = await client.snapshotList('web', project.id);
      if (!snapshot) {
        enqueueSnackbar('No snapshots available to analyze.', { variant: 'error' });
        return;
      }
      const job = await client.analysisCreate(project.id, snapshot.id);
      enqueueSnackbar(`Analysis job (${job.id}) created for snapshot ID ${snapshot.id}.`, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(`Failed to create analysis job: ${error}`, { variant: 'error' });
    }
  };

  const MetricCard = ({ icon, title, count, link }: MetricCardProps) => (
    <Tooltip title={`View details for ${title}`} arrow>
      <Card variant="outlined" sx={{ height: '100%', backgroundColor: 'grey.100' }}>
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={1}>
            {icon}
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          </Stack>
          <Typography variant="h4" sx={{ my: 2, textAlign: 'center' }}>
            {count}
          </Typography>
          <Button component={Link} to={link} endIcon={<LinkIcon />} fullWidth variant="outlined" size="small">
            View All
          </Button>
        </CardContent>
      </Card>
    </Tooltip>
  );

  return (
    <Box sx={{ p: 3 }}>
      {/* Project Header */}
      <Card elevation={2} sx={{ p: 3, mb: 3, backgroundColor: 'primary.light' }}>
        <Typography variant="h4" color="primary.contrastText" gutterBottom>
          {project.name}
        </Typography>
        <Typography variant="body1" color="primary.contrastText">
          {project.description}
        </Typography>
        <Typography variant="body1" color="primary.contrastText">
          Status: {project.enabled ? 'Enabled' : 'Disabled'}
        </Typography>

        {project.tags?.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Stack direction="row" spacing={1}>
              {project.tags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  size="small"
                  sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Card>

      {/* Quick Actions */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <ButtonGroup fullWidth>
          <Button
            startIcon={project.enabled ? <Pause /> : <KeyboardArrowRight />}
            onClick={handleDisableProject}
          >
            {project.enabled ? 'Disable' : 'Enable'} Project
          </Button>
          <Button startIcon={<Camera />} onClick={handleCreateSnapshot}>
            Queue Snapshot
          </Button>
          <Button startIcon={<Assessment />} onClick={handleCreateAnalysis}>
            Queue Analysis
          </Button>
        </ButtonGroup>
      </Paper>

      {/* Metrics Grid */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<ScreenshotMonitor color="primary" />}
            title="Snapshots"
            count={stats.snapshots}
            link={`/projects/${project.id}/snapshots`}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<Analytics color="primary" />}
            title="Analyses"
            count={stats.analyses}
            link={`/projects/${project.id}/analyses`}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<Science color="primary" />}
            title="Tests"
            count={stats.tests}
            link={`/projects/${project.id}/tests`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<Image color="primary" />}
            title="Assets"
            count={stats.assets}
            link={`/projects/${project.id}/assets`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<AccountTree color="primary" />}
            title="Roots"
            count={stats.roots}
            link={`/projects/${project.id}/roots`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<Dns color="primary" />}
            title="Domains"
            count={stats.domains}
            link={`/projects/${project.id}/domains`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<Hub color="primary" />}
            title="Dependencies"
            count={stats.dependencies}
            link={`/projects/${project.id}/dependencies`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            icon={<RocketLaunch color="primary" />}
            title="Deployments"
            count={stats.deployments}
            link={`/projects/${project.id}/deployments`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
