import { DeploymentAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Typography } from '@mui/material';
import { getSeverityColor } from './severity.js';

export function DeploymentAlert(alert: DeploymentAlertAPI) {
  const { severity } = alert;

  return (
    <Box>
      <Alert severity={getSeverityColor(severity)} sx={{ mb: 2 }}>
        <AlertTitle>New Deployment Detected</AlertTitle>
        <Typography variant="body2">
          A new deployment of the target website was detected. If this was unexpected, please investigate
          further to ensure everything is working as intended.
        </Typography>
      </Alert>

      <Card>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography variant="body2">Current Version: {alert.currentDeploymentId}</Typography>
          <Typography variant="body2">Previous Version: {alert.previousDeploymentId}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
