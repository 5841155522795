import { DependencyChangeAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Typography } from '@mui/material';
import SummarizedLink from '../url.js';
import { getSeverityColor } from './severity.js';

interface DependencyChangeAlertProps {
  alert: DependencyChangeAlertAPI;
}

export function DependencyChangeAlert({ alert }: DependencyChangeAlertProps) {
  const { severity, origins, history } = alert;

  const renderOrigins = (originList: typeof origins | string[]) => (
    <>
      {originList.map((item, index) => {
        const urlString = typeof item === 'string' ? item : item.request.url;
        return (
          <Typography key={index} variant="body2">
            <SummarizedLink url={urlString} />
          </Typography>
        );
      })}
    </>
  );

  return (
    <Box>
      <Alert severity={getSeverityColor(severity)} sx={{ mb: 2 }}>
        <AlertTitle>Dependency Changes Detected</AlertTitle>
        <Typography variant="body2">
          We found new dependencies in this request that may indicate a change in the website’s behavior. If
          this is unexpected, please investigate further.
        </Typography>
      </Alert>

      <Card>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography variant="body2">New origins:</Typography>
          {renderOrigins(origins)}
          <Typography variant="body2" sx={{ mt: 2 }}>
            Previously observed origins:
          </Typography>
          {renderOrigins(history.slice(0, 8))}
        </CardContent>
      </Card>
    </Box>
  );
}
