import { ContentChangeAlertAPI } from '@analyzer/client';
import { BuildHashMissMatchAlertAPI } from '@analyzer/core/lib/domain/data-transfer-objects/alert.js';
import { Alert, AlertTitle, Box, Button, Card, CardContent, Typography } from '@mui/material';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { useState } from 'react';
import { useService } from '../../providers/ServiceProvider.js';
import SummarizedLink from '../url.js';
import { getSeverityColor } from './severity.js';

function RawDifferencesComponent({ alert }: { alert: ContentChangeAlertAPI | BuildHashMissMatchAlertAPI }) {
  const [diffHtml, setDiffHtml] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const client = useService();

  const fetchDifferences = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Submit job for differences
      const job = await client.submitResponseBodyDiff(alert.request0.response.id, alert.request1.response.id);

      // Wait for the job to complete with a timeout safety
      await client.jobWaitForStatus(job.id, 'completed');

      // Fetch the differences
      const contents = await client.readResponseBodyDiff(
        alert.request0.response.id,
        alert.request1.response.id
      );

      // Generate HTML for differences
      const code = Diff2Html.html(contents, {
        outputFormat: 'line-by-line',
        drawFileList: false
      });

      setDiffHtml(code);
    } catch (err) {
      setError('Failed to fetch differences. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <Typography>Loading differences...</Typography>;
    }

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    if (diffHtml) {
      return <div dangerouslySetInnerHTML={{ __html: diffHtml }} />;
    }

    return (
      <Button onClick={fetchDifferences} disabled={isLoading}>
        Show Differences
      </Button>
    );
  };

  return <>{renderContent()}</>;
}

export function ContentChangeAlertComponent(alert: ContentChangeAlertAPI | BuildHashMissMatchAlertAPI) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle>Content Changes</AlertTitle>
        <Typography variant="body2">
          We found new dependencies in this request that may indicate a change in the website’s behavior.
        </Typography>
      </Alert>
      <Card>
        <CardContent>
          <Typography variant="h6">Details</Typography>
          <Typography variant="body2">
            The diff operation compares the following URLs from application snapshots:
          </Typography>
          <Typography variant="body2">
            <SummarizedLink
              title={alert.request0.request.url}
              url={`https://storage.googleapis.com/analyzer-blobs/http-response-bodies/${alert.request0.body.sha256}`}
            />
          </Typography>
          <Typography variant="body2">
            <SummarizedLink
              title={alert.request1.request.url}
              url={`https://storage.googleapis.com/analyzer-blobs/http-response-bodies/${alert.request1.body.sha256}`}
            />
          </Typography>
          <Typography variant="h6">Differences</Typography>
          <RawDifferencesComponent alert={alert} />
        </CardContent>
      </Card>
    </Box>
  );
}
