import { Alert, AlertSeverity } from '@analyzer/client';
import { Box, Paper, Theme, Typography, alpha } from '@mui/material';
import { AdditionalRequestAlert } from './AdditionalRequestAlert.js';
import { AntiDebugAlert } from './AntiDebugAlert.js';
import { ContentChangeAlertComponent } from './ContentChangeAlert.js';
import { DependencyChangeAlert } from './DependencyChangeAlert.js';
import { DeploymentAlert } from './DeploymentAlert.js';
import { ObfuscatedCodeAlert } from './ObfuscatedCodeAlert.js';
import { SignatureAlert } from './SignatureAlert.js';

export function RawAlertComponent({ alert }: { alert: Alert }) {
  return (
    <>
      <Typography variant="subtitle2">Alert Type</Typography>
      <Typography variant="body2">{alert.type || 'Unknown'}</Typography>

      <Typography variant="subtitle2">Description</Typography>
      <Typography variant="body2">Raw alert data display for debugging purposes.</Typography>

      <Typography variant="subtitle2">Details</Typography>
      <Typography variant="body2">Raw alert object:</Typography>
      <Box component="pre" sx={{ whiteSpace: 'pre-wrap', padding: 0 }}>
        {JSON.stringify(alert, null, 2)}
      </Box>
    </>
  );
}
const renderAlertSpecifics = (alert: Alert) => {
  try {
    switch (alert.type) {
      case 'anti-debug':
        return <AntiDebugAlert alert={alert} />;
      case 'signature-alert':
        return <SignatureAlert alert={alert} />;
      case 'transparency-log-alert':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when the transparency log has been updated with new entries.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new origins were detected:</Typography>
            <ul>
              {alert.records.map((record, index) => (
                <li key={index}>{JSON.stringify(record)}</li>
              ))}
            </ul>
          </>
        );
      case 'dependency-change':
        return <DependencyChangeAlert alert={alert} />;

      case 'ethereum-provider-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when new RPC providers are detected in the 'wallet_addEthereumChain'
              method calls made by the DApp that were not present in previous snapshots.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new RPC providers have been added:</Typography>
            <ul>
              {alert.new.map((url, index) => (
                <li key={index}>
                  <strong>RPC Provider URL:</strong> {url}
                </li>
              ))}
            </ul>
          </>
        );

      case 'ethereum-contract-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when new contracts are detected being called by the DApp that were not
              present in previous snapshots.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following new contracts have been called:</Typography>
            <ul>
              {alert.contracts.map((contract, index) => (
                <li key={index}>
                  <strong>Contract Address:</strong> {contract}
                </li>
              ))}
            </ul>
          </>
        );

      case 'google-safe-browsing':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered when URLs used by the DApp are found in Google's Safe Browsing database.
              These URLs may be associated with known threats, such as phishing or malware distribution.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following URL(s) were flagged:</Typography>
            <ul>
              {alert.results.map((result, index) => (
                <li key={index}>
                  <Typography variant="body2">
                    <strong>URL:</strong> {result.url}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Threat Type:</strong> {result.threatType || 'Unknown'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Platform Type:</strong> {result.platformType || 'Unknown'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Threat Entry Type:</strong> {result.threatEntryType || 'Unknown'}
                  </Typography>
                </li>
              ))}
            </ul>
          </>
        );

      case 'obfuscated-code':
        return <ObfuscatedCodeAlert alert={alert} />;

      case 'additional-request':
        return AdditionalRequestAlert(alert);

      case 'insecure-connection':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              An Insecure Connection Alert is triggered when connections that do not use secure protocols
              (like HTTPS) are detected. Secure connections are important as they help keep information
              transferred between your website and its users private and secure.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The following insecure connections were detected:</Typography>
            {alert.connections.map((connection, index) => (
              <div key={index}>
                <Typography variant="body2">Connection Address: {connection.connection.address}</Typography>
              </div>
            ))}
          </>
        );

      case 'certificate-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert is triggered by TLS certificate changes for a specific resource. While certificate
              renewals are common due to expiration, changes can also stem from security incidents, such as
              server hijacking, where an attacker issues a rogue certificate.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>

            {alert.change.type === 'unexpected-issuer' && (
              <>
                <Typography variant="body2">Unexpected Certificate Issuer:</Typography>
                <Typography variant="body2" gutterBottom>
                  {alert.change.issuer}
                </Typography>
                <Typography variant="body2">Past Certificate Issuers:</Typography>
                <ul>
                  {alert.change.issuers.map((issuer, idx) => (
                    <li key={idx}>{issuer}</li>
                  ))}
                </ul>
              </>
            )}

            {alert.change.type === 'expected-renewal' && (
              <Typography variant="body2" component="div">
                Expected Certificate Renewal:
                <ul>
                  {alert.change.expirations.map((expiration, idx) => (
                    <li key={idx}>Certificate expires in {expiration} day(s)</li>
                  ))}
                </ul>
              </Typography>
            )}

            {alert.change.type === 'unexpected-renewal' && (
              <Typography variant="body2">
                A certificate renewal occurred unexpectedly, without nearing expiration.
              </Typography>
            )}
          </>
        );

      case 'status-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              A Status Change Alert is issued when the application transitions to an unreachable state. This
              could indicate network issues, server downtime, or unexpected errors within the application.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">The application became unreachable at approximately:</Typography>
            <Typography variant="body2">
              <strong>{new Date(alert.timestamp).toLocaleString()}</strong>
            </Typography>
          </>
        );

      case 'build-hash-mismatch':
      case 'content-change':
        return ContentChangeAlertComponent(alert);

      case 'dns-record-change':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              This alert notifies of alterations in the DNS records for your domain. DNS records are pivotal
              in directing internet traffic, influencing both the accessibility and security of your website.
            </Typography>

            <Typography variant="subtitle2">Details</Typography>
            <Typography variant="body2">Notable DNS record changes include:</Typography>
            <ul>
              {alert.changes.map((change, idx) => (
                <li key={idx}>
                  Record of type {change.recordType} with value {change.record} was {change.type}
                </li>
              ))}
            </ul>
          </>
        );
      case 'analyzer-blocked':
        return (
          <>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              An Analyzer Blocked Alert is triggered when the analyzer bot has been blocked by the target.
            </Typography>
          </>
        );
      case 'deployment-alert':
        return DeploymentAlert(alert);
    }
  } catch (error) {
    return RawAlertComponent({ alert });
  }
};

const AlertColorMap: Record<AlertSeverity, string> = {
  ['high']: '#ad0424',
  ['medium']: '#d7667b',
  ['low']: '#abe4b4',
  ['info']: '#abbfe4'
};

function usePaperStyles(alertSeverity: string) {
  return (theme: Theme) => ({
    borderLeft: `10px solid ${alertSeverity}`,
    bgcolor: alpha(alertSeverity, 0.01),
    padding: theme.spacing(2)
  });
}

export function AlertComponent({ alert }: { alert: Alert }) {
  const styles = usePaperStyles(AlertColorMap[alert.severity]);

  return <Paper sx={styles}>{renderAlertSpecifics(alert)}</Paper>;
}
