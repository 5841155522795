import { AdditionalRequestAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Typography } from '@mui/material';
import SummarizedLink from '../url.js';
import { getSeverityColor } from './severity.js';

export function AdditionalRequestAlert(alert: AdditionalRequestAlertAPI) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle>Additional Requests</AlertTitle>
        <Typography variant="body2">
          We have detected one or more requests that have not been observed before.
        </Typography>
      </Alert>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Newly Observed Requests
          </Typography>
          {alert.requests.map((item, index) => (
            <Typography key={index} variant="body2">
              <SummarizedLink url={item.request.url} />
            </Typography>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}
