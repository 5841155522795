import { ObfuscationAlertAPI } from '@analyzer/client';
import { Alert, AlertTitle, Box, Card, CardContent, Paper, Typography } from '@mui/material';
import SummarizedLink from '../url.js';
import { getSeverityColor } from './severity.js';

export function ObfuscatedCodeAlert({ alert }: { alert: ObfuscationAlertAPI }) {
  return (
    <Box>
      <Alert severity={getSeverityColor(alert.severity)} sx={{ mb: 2 }}>
        <AlertTitle sx={{ fontSize: '1.1rem' }}>Obfuscated Code</AlertTitle>
        <Typography variant="body2">
          The system has detected obfuscated code, which may indicate hidden or malicious functionality.
        </Typography>
      </Alert>

      <Paper elevation={2}>
        <Card>
          <CardContent>
            <Box sx={{ '& > :not(:last-child)': { mb: 3 } }}>
              <Box>
                <Typography variant="h6" gutterBottom>
                  Description
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Obfuscated code has been identified in the system. The detected obfuscation method is
                  <strong>{alert.reason}</strong>.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Request
                </Typography>
                <Box>
                  <Typography variant="body2">
                    <strong>URL:</strong>
                    <SummarizedLink
                      title={alert.requests[0]!.request.url}
                      url={`https://storage.googleapis.com/analyzer-blobs/http-response-bodies/${
                        alert.requests[0]!.body.sha256
                      }`}
                    />
                  </Typography>
                  <Typography variant="body2">
                    <strong>Method:</strong> {alert.requests[0]!.request.method}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Content Type:</strong> {alert.requests[0]!.response.contentType}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Hostname:</strong> {alert.requests[0]!.connection.hostname}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Port:</strong> {alert.requests[0]!.connection.port}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Secure Connection:</strong> {alert.requests[0]!.connection.secure ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
}
